<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <v-btn
      color="red darken-3"
      class="ml-7 my-back-btn-style"
      text
      x-large
      dark
      @click="back()"
    >
      <v-icon
        left
        dark
      >
        mdi-arrow-left-bold
      </v-icon>
      Back
    </v-btn>
    <br>
    <h2 class="title-style">
      <v-icon
        class="icon-style"
        left
      >
        mdi-chart-box-plus-outline
      </v-icon> Tracking Instances
    </h2>
    <v-btn
      v-if="showSyncButton"
      text
      outlined
      class="sync-btn"
      @click="syncTrackingItemInstancesFromPartner()"
    >
      <v-icon
        color="#37474F"
        size="30"
        left
      >
        mdi-cloud-sync-outline
      </v-icon>
      <span class="sync-btn-txt"> Sync Autodesk </span>
    </v-btn>
    <v-card
      shaped
      color="#CFD8DC"
    >
      <br>
      <div v-if="isTrackingItemFromPartner">
        <br>
        <br>
      </div>
      <v-col
        v-if="!isTrackingItemFromPartner"
        class="text-right"
      >
        <v-btn
          color="#FF3700"
          @click="showInstanceCreateForm = true"
        >
          Add New
        </v-btn>
      </v-col>
      <hr>
      <br>
      <v-card-title>
        <v-row>
          <v-col
            cols="12"
            lg="4"
            md="5"
            sm="12"
          >
            <v-text-field
              v-model="search"
              label="Search"
              append-icon="mdi-magnify"
              outlined
              dense
            />
          </v-col>
        </v-row>
      </v-card-title>
      <p
        v-if="partnerCheck()"
        class="info-text"
      >
        *Indicates from partner account
      </p>
      <v-data-table
        :headers="headers"
        :items="trackingItemInstances"
        :search="search"
        class="elevation-1 clickable"
        disable-sort
        mobile-breakpoint="100"
        light
      >
        <template v-slot:item="{ item }">
          <tr>
            <td
              class="more-link"
              @click="showInstanceMoreDetails(item.id)"
            >
              {{ item.name }}<span
                v-if="item.database === false"
                class="partner-project"
              >*</span>
            </td>
            <td>{{ item.input_quantity }} <span class="hrs-style">Hrs</span></td>
            <td>{{ item.output_quantity }} <span class="hrs-style">{{ item.output_unit }}</span></td>
            <td>{{ item.tracked_input_quantity }} <span class="hrs-style">Hrs</span></td>
            <td>{{ item.tracked_output_quantity }} <span class="hrs-style">{{ item.output_unit }}</span></td>
            <td>
              <font
                class="ratio-font"
                :color="getPerformanceRatioColor(item.performance_ratio)"
              >
                {{ item.performance_ratio | formatRatio }} <v-icon
                  v-if="item.performance_ratio >= 1"
                  color="#2E7D32"
                >
                  mdi-arrow-up-thin
                </v-icon>
                <v-icon
                  v-else
                  color="#C62828"
                >
                  mdi-arrow-down-thin
                </v-icon>
              </font>
            </td>
            <td>
              <v-btn
                class="mt-n2"
                elevation="1"
                fab
                x-small
                icon
                color="#37474F"
                @click="showInstanceMoreDetails(item.id)"
              >
                <v-icon>mdi-eye</v-icon>
              </v-btn>
            </td>
            <td>
              <v-btn
                class="mt-n2"
                elevation="1"
                fab
                x-small
                icon
                color="#37474F"
                @click="editTrackingItemInstance(item.id)"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </td>
            <td>
              <v-btn
                class="mt-n2"
                elevation="1"
                fab
                x-small
                :disabled="item.database === false"
                icon
                color="#C62828"
                @click="deleteTrackingItemInstance(item.id)"
              >
                <v-icon>mdi-trash-can</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
      <br>
    </v-card>
    <centre-spinner
      :loading="loading"
    />
    <partner-auth-check
      ref="partnerAuth"
      @partner-authorized="getTrackingItemInstancesFromPartner"
    />
    <overlay-alert
      :show-progress="showProgress"
      :process-alert="processAlert"
    />
    <add-tracking-item-instance
      v-if="showInstanceCreateForm"
      @closed="showInstanceCreateForm = false"
      @success="getTrackingItemInstances"
    />
    <delete-tracking-item-instance
      v-if="showDeleteDialog"
      :title="'Delete this tracking item instance?'"
      :message="'Action will permanently remove this performance tracking item instance from the list, Do you want to continue ?'"
      @closed="showDeleteDialog = false"
      @confirmed="deleteConfirmed()"
    />
    <edit-tracking-item-instance
      v-if="showInstanceEditForm"
      :tracking-item-instance-id="trackingItemInstanceId"
      @closed="showInstanceEditForm = false"
      @success="getTrackingItemInstances"
    />
  </v-container>
</template>

<script>
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import global from 'src/mixins/global';
  import PartnerAuthCheck from 'src/views/dashboard/partnerauth/PartnerAuthCheck';
  import OverlayAlert from 'src/views/dashboard/component/OverlayAlert';
  import AddTrackingItemInstance from './AddTrackingItemInstance';
  import DeleteDialog from 'src/views/dashboard/component/DeleteDialog';
  import Constants from 'src/constants';
  import EditTrackingItemInstance from './EditTrackingItemInstance';

  export default {
    name: 'TrackingItemInstances',
    components: {
      'centre-spinner': spinner,
      'partner-auth-check': PartnerAuthCheck,
      'overlay-alert': OverlayAlert,
      'add-tracking-item-instance': AddTrackingItemInstance,
      'delete-tracking-item-instance': DeleteDialog,
      'edit-tracking-item-instance': EditTrackingItemInstance,
    },
    filters: {
      formatRatio (val) {
        return Number(val).toFixed(2);
      },
    },
    mixins: [global],
    data () {
      return {
        showModal: true,
        search: '',
        headers: [
          { text: 'Name', align: 'left', value: 'name' },
          { text: 'Input Qty', value: 'input_quantity' },
          { text: 'Output Qty', value: 'output_quantity' },
          { text: 'Tracked Input Qty', value: 'tracked_input_quantity' },
          { text: 'Tracked Output Qty', value: 'tracked_output_quantity' },
          { text: 'Performance Ratio', value: 'performance_ratio' },
          { text: 'More', value: 'more' },
          { text: 'Edit', value: 'edit' },
          { text: 'Delete', value: 'delete' },

        ],
        loading: false,
        showProgress: false,
        processAlert: '',
        showInstanceCreateForm: false,
        showDeleteDialog: false,
        trackingItemInstanceId: '',
        showInstanceEditForm: false,
        showSyncButton: false,
        isTrackingItemFromPartner: false,
      };
    },
    computed: {
      trackingItemInstances () {
        return this.$store.getters['projects/getTrackingItemInstances'];
      },
      projectDetails () {
        return this.$store.getters['projects/getProjectDetails'];
      },
      performanceTrackingItems () {
        return this.$store.getters['projects/getPerformanceTrackingItems'];
      },
    },
    async mounted () {
      this.loading = true;
      await this.getProjectDetails();
      await this.getTrackingItemInstances();
      await this.getPerformanceTrackingItems();
      this.loading = false;
    },
    methods: {
      async getPerformanceTrackingItems () {
        this.loading = true;
        await this.$store.dispatch('projects/fetchPerformanceTrackingItems', {
          projectId: this.$route.params.projectId,
        }).then(() => {
          const trackingItemId = this.$route.params.trackingItemId;
          const trackingItem = this.performanceTrackingItems.filter(item => item.id === trackingItemId);
          if (this.projectDetails.database === false && trackingItem[0].database === false) {
            this.showSyncButton = true;
            this.isTrackingItemFromPartner = true;
            return;
          }
          this.showSyncButton = false;
          this.isTrackingItemFromPartner = false;
        }).catch(() => {
          this.loading = false;
        });
        this.loading = false;
      },
      back () {
        this.$store.dispatch('projects/storeProjectSecondNavDrawerTab', {
          toTab: 7,
        });
        const projectId = this.$route.params.projectId;
        this.$router.push(`/projects/${projectId}/details`);
      },
      async getProjectDetails () {
        await this.$store.dispatch('projects/fetchProjectDetails', {
          projectId: this.$route.params.projectId,
        });
      },
      async getTrackingItemInstances () {
        this.loading = true;
        await this.$store.dispatch('projects/fetchTrackingItemInstances', {
          trackingItemId: this.$route.params.trackingItemId,
        });
        this.loading = false;
      },
      showInstanceMoreDetails (trackingIntanceId) {
        const projectId = this.$route.params.projectId;
        const trackingItemId = this.$route.params.trackingItemId;
        this.$router.push(`/projects/${projectId}/performance-tracking-items/${trackingItemId}/instances/${trackingIntanceId}/details`);
      },
      getPerformanceRatioColor (val) {
        if (val >= 1) {
          return '#2E7D32';
        }
        return '#C62828';
      },
      syncTrackingItemInstancesFromPartner () {
        this.$refs.partnerAuth.isPartnerAuthorized();
      },
      async getTrackingItemInstancesFromPartner () {
        this.showProgress = true;
        this.processAlert = 'Syncing...';
        await this.$store.dispatch('partners/fetchTrackingItemInstancesFromPartner', {
          projectId: this.$route.params.projectId,
        }).then(response => {
          this.showProgress = false;
          this.getTrackingItemInstances();
        }).catch(() => {
          this.showProgress = false;
        });
      },
      deleteTrackingItemInstance (id) {
        this.trackingItemInstanceId = id;
        this.showDeleteDialog = true;
      },
      async deleteConfirmed () {
        this.loading = true;
        this.showDeleteDialog = false;
        await this.$store.dispatch('projects/deleteTrackingItemInstance', {
          trackingItemInstanceId: this.trackingItemInstanceId,
        }).then(response => {
          this.$store.dispatch('alert/onAlert', {
            message: 'Performance tracking item instance deleted successfully.',
            type: Constants.ALERT_TYPE_SUCCESS,
          });
          this.loading = false;
          this.getTrackingItemInstances();
        }).catch(() => {
          this.loading = false;
        });
      },
      editTrackingItemInstance (id) {
        this.trackingItemInstanceId = id;
        this.showInstanceEditForm = true;
      },
    },
  };
</script>

<style scoped>
.base-card {
  padding-right: 30px;
  padding-left: 30px;
}
.title-style {
  color: #37474F;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.close-button {
  float: right;
  margin-right: 20px;
  cursor: pointer;
  color: #37474F;
}
.title-header {
  font-family: 'Times New Roman', Times, serif;
  font-weight: bolder;
  margin-left: 5px;
  color: #37474F;
  font-size: 18px;
}
.title-name {
  color: #FF3700;
}
@media (max-width: 960px) {
  .title-header {
    margin-top: 30px;
  }
}
.v-data-table::v-deep th {
  font-size: 12px !important;
  color: #37474F !important;
  font-weight: bold !important;
}
.v-data-table::v-deep td {
  font-size: 13px !important;
  color: #37474F !important;
}
.theme--light.v-data-table tbody tr:nth-of-type(even) {
  background-color: #CFD8DC
}
.v-data-table { background-color: #ECEFF1; }
.hrs-style {
  color: #37474F;
  font-family: 'Times New Roman', Times, serif;
  font-size: 11px;
  text-transform: capitalize;
}
.sync-btn-txt {
  color: #FF3700;
  font-weight: bold;
  font-family: 'Times New Roman', Times, serif;
  text-transform: capitalize;
  margin-top: 3px;
  margin-left: 5px;
  font-size: 15px;
}
.sync-btn {
  margin-left: 8px;
  margin-top: 10px;
}
.info-text {
  font-family: 'Times New Roman', Times, serif;
  font-weight: bold;
  font-style: italic;
  color: #37474F;
  margin-left: 15px;
  margin-top: -20px;
}
.my-back-btn-style {
 margin-left: -15px !important;
 margin-bottom: 8px;
}
.partner-project {
  color: #FF3700;
  font-weight: bold;
}
.alert-style {
  font-family: 'Times New Roman', Times, serif;
  font-size: 16px;
}
.ratio-font {
  font-weight: bold;
}
.more-link{
  cursor: pointer;
}
</style>
